import axios from "axios"
 const basurl = 'https://blogs-app-7fcl.onrender.com'
//const basurl = 'http://localhost:5002'
export const register = async (data) => {
  const { username, password, email } = data
  try {
    const response = await axios.post(
      `${basurl}/api/users`,
      { username, password, email },

    );
    return response.data;


    // You can perform further actions after successful registration here

  } catch (error) {
    console.error('Registration failed:', error);
    // Handle error (e.g., display an error message to the user)
  }
}

export const login = async (data) => {
  const { username, password, email } = data
  try {
    const response = await axios.post(
      `${basurl}/api/login`,
      { password, email },

    );
    return response.data;


    // You can perform further actions after successful registration here

  } catch (error) {
    console.error('Registration failed:', error);
    // Handle error (e.g., display an error message to the user)
  }
}


export const addblog = async (data) => {
  const tokenString = localStorage.getItem("token");
  const token = JSON.parse(tokenString);

  const { title, desscription, category } = data
  try {
    const response = await axios.post(
      `${basurl}/api/addblog`,
      { title, desscription, category },
      {
        headers: {
          Authorization: `Bearer ${token}` // Include JWT token in the request headers
        }
      }
    );
    return response.data;


    // You can perform further actions after successful registration here

  } catch (error) {
    console.error('Registration failed:', error);
    // Handle error (e.g., display an error message to the user)
  }
}

export const allblog = async (data) => {

  try {
    const response = await axios.post(
      `${basurl}/api/allblog`,{data}
    );
    return response.data;


    // You can perform further actions after successful registration here

  } catch (error) {
    console.error('Registration failed:', error);
    // Handle error (e.g., display an error message to the user)
  }
}


export const blog = async (id) => {

  try {
    const response = await axios.post(
      `${basurl}/api/blogById`,
      { id },

    );
    return response.data;


    // You can perform further actions after successful registration here

  } catch (error) {
    console.error('Registration failed:', error);
    // Handle error (e.g., display an error message to the user)
  }
}
