import React, { useEffect, useState } from 'react'
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './web/Home';
import Header from './Header';
import Bolg from './web/Bolg';
import Rigester from './Componts/Rigester';
import Login from './Componts/Login';
import Blogid from './Componts/Blogid';
import AddBlog from './Componts/AddBlog';
import BlogBook from './web/BlogBook';

function App() {
  const [loginuser,setloginuser]=useState()

  
   useEffect(()=>{
  const loginuserss =  localStorage.getItem("token")
  setloginuser(loginuserss)
  console.log(loginuser?.length);
   },[])
  return (
   <>
   <Router>
 <Header loginuser={loginuser}/>

      <Routes>
        <Route path="/" element={<Home />} />
     <Route path="/blog" element={<Bolg />} />
        <Route path="/login" element={<Login/>}/>
        <Route path="/rigester" element={<Rigester />} />
        <Route path="/readMore/:id" element={<Blogid />} />
        <Route path="/addblog" element={<AddBlog/>} />
        <Route path="/blogbook" element={<BlogBook/>} />
         <Route path="/addblog" element={loginuser?.length>0 &&<AddBlog/>} />

      </Routes>
    </Router>
   </>
  );
}

export default App;
