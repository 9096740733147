import React from 'react'
import Slider from '../Componts/Slider'


const Home = () => {
  return (
   <>
   
 

  
   <Slider className="my-4"/>

   <h1 className='text-center my-4'>The Sikh Gazette

</h1>
   </>
  )
}

export default Home